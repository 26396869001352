@import '~antd/dist/antd.css';

body {
  font-family: 'Roboto', sans-serif;
}

.site-content {
  min-height: 280px;
}

.content-module {
  background: #fff;
  padding: 24px;
}

.logo {
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #bbb;
  letter-spacing: 0.5px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;

  strong {
    color: #fff;
  }
}
